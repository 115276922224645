import React, { useEffect, useState, useRef } from 'react';

import Img from 'gatsby-image';
import { Link } from 'gatsby';

import { useInterval, useOnScreen } from '../../../utils/hooks';
import ApartStep from '../../ApartStep';
import ArrowButtonText from '../../ArrowButtonText';
import Button from '../../Button';
import Dots from '../../Dots';
import greyDot from '../../../img/grey-dot.svg';
import LBCircle from '../../../img/lb-sm-circle.svg';
import './index.css';
import chevron from '../../../img/chevron-right.svg';
import shadowCard from '../../../img/shadow-card.svg';
import setsApartBackground from '../../../img/sets-apart-background.svg';

const defaultDelay = 12000;

export default ({ steps }) => {
  const numSteps = 5;
  const ref = useRef();
  const [currentStep, setCurrentStep] = useState(0);
  const [delay, setDelay] = useState(defaultDelay);
  const isVisible = useOnScreen(ref);
  const [isHovered, setIsHovered] = useState(false);
  const linkAddress = 'https://deptagency.com/en-us/services';
  const linkText = 'Our Services';

  useEffect(() => {
    if (!delay) setDelay(defaultDelay);
  }, [delay]);

  useEffect(() => {
    if (isVisible && !currentStep) setCurrentStep(0);
  }, [isVisible, currentStep]);

  useInterval(() => {
    setCurrentStep((currentStep + 1) % numSteps);
  }, delay);

  const right = () => (
    <div className="right">
      <h2>{steps[currentStep].contentTitle}</h2>
      <p>{steps[currentStep].contentBody}</p>
    </div>
  );

  const slider = () => (
    <div className="slider">
      <img
        onClick={() => handleChangeStep(currentStep - 1)}
        className={`chevron-left`}
        src={chevron}
        alt="Chevron"
      />
      <p>0{currentStep + 1}</p>
      <p className="divider">/</p>
      <p>0{numSteps}</p>
      <img
        onClick={() => handleChangeStep(currentStep + 1)}
        className={`chevron-right`}
        src={chevron}
        alt="Chevron"
      />
    </div>
  );

  const center = () => (
    <div className="center">
      <img className="lb-circle" src={LBCircle} alt="Light blue circle" />
      <img className="grey-dot" src={greyDot} alt="Grey dots" />
      <div className="image-container">{image()}</div>
    </div>
  );

  const image = () => (
    <div>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <a href={linkAddress}>
          <img className="shadow-card" src={shadowCard} alt="Shadow card" />
          <Img
            className="apart-image"
            fluid={steps[currentStep].image.src.childImageSharp.fluid}
            alt={steps[currentStep].image.alt}
            loading="eager"
            imgStyle={{
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </a>
      </div>
    </div>
  );

  const imageMobile = () => (
    <div>
      <img className="shadow-card" src={shadowCard} alt="Shadow card" />
      <Img
        className="apart-image"
        fluid={steps[currentStep].image.src.childImageSharp.fluid}
        alt={steps[currentStep].image.alt}
        loading="eager"
        imgStyle={{
          objectFit: 'cover',
          objectPosition: 'center'
        }}
      />
    </div>
  );

  const background = () => (
    <img
      className="sets-apart-background"
      src={setsApartBackground}
      alt="Background"
    />
  );

  const handleChangeStep = (step) => {
    setDelay(null);
    setCurrentStep((numSteps + step) % numSteps);
  };

  const renderSteps = () =>
    steps.map((step, index) => (
      <ApartStep
        key={index}
        text={step.title}
        number={index}
        currentStep={currentStep}
        onClick={() => handleChangeStep(index)}
      />
    ));

  return (
    <div className="section-container" ref={ref}>
      <section className="sets-apart root-padding">
        <a href={linkAddress} className={isHovered ? 'hovered' : ''}>
          <ArrowButtonText text={linkText} />
        </a>
        {background()}
        <div className="left left-steps">
          <h2>Services</h2>
          <div className="steps">{renderSteps()}</div>
        </div>
        {center()}
        {right()}
      </section>
      <section className="sets-apart tablet root-padding">
        {background()}
        <div className="top">
          <div>
            <Dots amount={6} />
            <h2>Services</h2>
          </div>
          {slider()}
        </div>
        {renderSteps()}
        <div className="bottom">
          {right()}
          {center()}
        </div>
      </section>
      <section className="sets-apart mobile root-padding">
        {background()}
        <Dots amount={6} />
        <h2>Services</h2>
        <div className="image-container">{imageMobile()}</div>
        <div className="center">
          {renderSteps()}
          {slider()}
        </div>
        {right()}
        <a href={linkAddress}>
          <Button text={linkText} />
        </a>
      </section>
    </div>
  );
};
