import React, { useRef } from 'react';
import { useCompleteOnScreen } from '../../../utils/hooks';
import Img from 'gatsby-image';

import { AnimatedDots } from '../../Dots';
import './index.css';

export default ({ logos }) => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);

  return (
    <section ref={ref} className="partnering root-padding">
      <div className="top">
        <AnimatedDots completeOnScreen={completeOnScreen} />
        <h2 className="has-text-white">
          Folks we’ve had the pleasure of partnering with
        </h2>
      </div>
      <div className="logos">
        {logos.map(({ src, alt }, index) => (
          <Img key={index} fluid={src.childImageSharp.fluid} alt={alt} />
        ))}
      </div>
    </section>
  );
};
