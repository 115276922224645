import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'gatsby';

import Button from '../../Button';
import { AnimatedDots } from '../../Dots';
import './index.css';
import ContactForm from '../../ContactForm';
import heroDownLeft from '../../../img/hero-down-left.svg';
import heroDownCenter from '../../../img/hero-down-center.svg';
import heroDownRight from '../../../img/hero-down-right.svg';
import background from '../../../img/contact-zebra-background.svg';

const navbarHeight = 87;
const timeToShowButton = 44;

export default () => {
  const ref = useRef();
  const [trigger, setTrigger] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTrigger(true);
      setButtonVisible(true);
    }, timeToShowButton * 1000);
  }, []);

  const scrollNextSection = () => {
    window.scrollTo(
      0,
      ref.current.getBoundingClientRect().height - navbarHeight
    );
  };

  return (
    <section ref={ref} className="main-hero root-padding">
      <img className="background" src={background} alt="background" />
      <div onClick={scrollNextSection} className="hero-down-container">
        <img className="down-left" src={heroDownLeft} alt="Go down button" />
        <img
          className="down-center"
          src={heroDownCenter}
          alt="Go down button"
        />
        <img className="down-right" src={heroDownRight} alt="Go down button" />
      </div>
      <div className="left">
        <AnimatedDots completeOnScreen={trigger} />
        <h1>Devetry is now DEPT®</h1>
        <p>
          Experts in everything software, we provide UX/UI design, custom
          software development, and innovation services. As part of the global
          digital agency DEPT®, we bring these same services, but with a scaled,
          global reach.
        </p>
        <p>
          <a
            href="https://www.deptagency.com/en-us/?utm_source=devetry&utm_medium=referral&utm_campaign=bowery"
            className="visit-dept-btn"
            target="_blank"
          >
            Visit DEPT®
          </a>
        </p>
      </div>
      <div className="right">
        <div className="circle" />
        <svg
          className="ellipse"
          xmlns="http://www.w3.org/2000/svg"
          width="162"
          height="162"
          viewBox="-20 -20 200 200"
        >
          <g className="a">
            <circle className="b" cx="81" cy="81" r="81" />
            <circle className="c" cx="81" cy="81" r="64" />
          </g>
        </svg>
        <div className="hidden-circle">
          <div className="circle2" />
        </div>
        <span className="decoration"></span>
        <ContactForm />
      </div>
    </section>
  );
};
