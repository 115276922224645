import React, { useEffect, useState } from 'react';
import './index.css';

export default ({ currentStep, text, number, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`step ${
        currentStep === number ? 'active' : ''
      }`}
    >
      <div className="step-number">0{number}</div>
      <div className="right">
        <p>{text}</p>
        <div className="progress-bar">
          <div></div>
        </div>
      </div>
    </div>
  );
};
