import React, { useRef, useEffect, useState } from 'react';

import { Link } from 'gatsby';

import './index.css';
import { getCareers } from '../../../services/CareersService';
import { useCompleteOnScreen } from '../../../utils/hooks';
import { AnimatedDots } from '../../Dots';
import CareersList from '../../CareerList';
import blueDot from '../../../img/blue-dot.svg';
import ourStory from '../../../img/people-careers@2x.png';
import orangeEllipse from '../../../img/orange-ellipse.svg';

const CareersSection = () => {
  const ref = useRef();
  const isCompleteOnScreen = useCompleteOnScreen(ref);
  const [careers, setCareers] = useState(undefined);

  useEffect(() => {
    getCareers(3).then((result) => setCareers(result));
  }, []);

  return (
    <section ref={ref} className="people-careers root-padding">
      <div className="left">
        <p className="heading">Interested in a career in tech? View our current openings</p>
        <AnimatedDots completeOnScreen={isCompleteOnScreen} />
        <CareersList items={careers} />
        <a href="https://www.deptagency.com/en-us/careers/" className="btn">
          Explore all careers
        </a>
      </div>
      <div className="right">
        <img
          src={blueDot}
          alt="blue-dot"
          className="blue-dot"
        />
        <img
          src={ourStory}
          alt="our-story"
          className="section-img"
        />
        <img
          src={orangeEllipse}
          alt="orange-ellipse"
          className="orange-ellipse"
        />
      </div>
    </section>
  );
};

export default CareersSection;
