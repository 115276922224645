import React, { useRef, Fragment } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { useCompleteOnScreen } from '../../../utils/hooks';

import { AnimatedDots } from '../../Dots';
import { TButton } from '../../Button';
import ArrowButton from '../../ArrowButton';
import blueDot from '../../../img/blue-dot.svg';
import './index.css';

export default ({ title, items = [] }) => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);

  const getRedirectLink = (slug) => {
    if (slug === '/work/education-for-humanity/') {
      return 'https://www.deptagency.com/en-us/case/bridging-opportunity-gap-between-person-students-non-traditional-learners/';
    }
    if (slug === '/work/charter-communications/') {
      return 'https://www.deptagency.com/en-us/case/visualizing-capacity-planning/';
    }
    if (slug === '/work/intelligent-campus/') {
      return 'https://www.deptagency.com/en-us/case/driving-sustainability-with-data-visualization/';
    }
  };

  return (
    <section className="root-padding recent-work">
      <div className="top">
        <div>
          <AnimatedDots completeOnScreen={completeOnScreen} />
          <h2 ref={ref}>{title}</h2>
        </div>
        <div>
          <Link to="https://www.deptagency.com/en-us/work/">
            <TButton text="see more" />
          </Link>
        </div>
      </div>
      <div className="content">
        {items.map((item, index) => (
          <Fragment key={index}>
            <Link to={getRedirectLink(item.slug)}>
              <BackgroundImage
                Tag="section"
                className="work-card"
                fluid={item.cardImage.src.childImageSharp.fluid}
                backgroundColor={`#040e18`}
              >
                <ArrowButton />
                <div className="work-card-gradient"></div>
                <p className="topic">{item.title}</p>
                <img className="b-circle" src={blueDot} alt="blue-dot" />
                <div>
                  <Img
                    className="logo"
                    fluid={item.heroLogo.src.childImageSharp.fluid}
                    alt={item.heroLogo.alt}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </div>
              </BackgroundImage>
            </Link>
          </Fragment>
        ))}
      </div>
    </section>
  );
};
