import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

// Sections
import Hero from '../../components/homeSections/Hero';
import SetsApart from '../../components/homeSections/SetsApart';
import Partnering from '../../components/homeSections/Partnering';
import RecentWork from '../../components/homeSections/RecentWork';
import CareersSection from '../../components/peopleSections/CareerSection';

const pageTitle =
  'Experts in Everything Software | Development, Strategy, UX | Devetry';
const pageDescription =
  "We'd love to help you strategize, design, and build your next software product. Learn more about Devetry and why we're experts in everything software.";

const IndexPageTemplate = ({
  setUsApartSteps,
  partneringLogos,
  recentWork
}) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container">
        <Hero />
        <SetsApart steps={setUsApartSteps} />
        <Partnering logos={partneringLogos} />
        <RecentWork title="Recent work" items={recentWork} />
        <CareersSection />
      </div>
    </>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string
};

const IndexPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;
  const { edges } = data.recentWork;
  const recentWork = edges.map((e) => ({
    ...e.node.frontmatter,
    slug: e.node.fields.slug
  }));

  return (
    <IndexPageTemplate
      setUsApartSteps={frontmatter.steps}
      partneringLogos={frontmatter.partneringLogos}
      recentWork={recentWork}
    />
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Home/index" } }) {
      fields {
        blogItem1 {
          fields {
            slug
          }
          frontmatter {
            title
            date
            category
            coverImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }
          }
        }
        blogItem2 {
          fields {
            slug
          }
          frontmatter {
            title
            date
            category
            coverImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }
          }
        }
      }
      frontmatter {
        templateKey
        steps {
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
          title
          contentTitle
          contentBody
        }
        partneringLogos {
          src {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
        }
      }
    }
    recentResources: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "Resource/index" } } }
      limit: 3
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            url
            photo {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    recentWork: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "CaseStudy/index" } } }
      limit: 3
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            heroLogo {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }
            cardImage {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }
            title
          }
        }
      }
    }
  }
`;
